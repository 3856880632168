/**************************************
    Default Styles
***************************************/

// @import url('../css/bootstrap/bootstrap-utilities.css');
// @import url('../css/bootstrap/bootstrap-grid.css');
@import url('../css/slick.css');
@import url('../css/slicktheme.css');
@import url('../css/animation.css');


@import './default/variables';
@import './default/mixins';
@import './default/reset';
@import './default/typography';
@import './default/extend';
@import './default/animations';
@import './default/shortcode';
@import './default/spacing';
@import './default/common';
@import './default/forms';


/**************************************
    Theme Styles
***************************************/

@import './color/themebg';

/**************************************
    Header Styles
***************************************/
@import './header/header';
@import './header/nav';
@import './header/mobilemenu';
@import './header/headertop';


/**************************************
    Elements Styles
***************************************/
@import './elements/sctiontitle';
@import './elements/button';
@import './elements/breadcrumb';
@import './elements/accordion';
@import './elements/service';
@import './elements/card';
@import './elements/progressbar';
@import './elements/counter';
@import './elements/portfolio';
@import './elements/social';
@import './elements/team';
@import './elements/timeline';
@import './elements/testimonial';
@import './elements/slickslide';
@import './elements/tab';
@import './elements/pricing';
@import './elements/split';
@import './elements/callto-action';
@import './elements/video';
@import './elements/contact';
@import './elements/brand';
@import './elements/newsletter';
@import './elements/advance-tab';
@import './elements/advancepricing';
@import './elements/about';
@import './elements/swicher';
@import './elements/404';



/**************************************
    Blog Styles
***************************************/

@import './blog/blog';
@import './blog/sidebar';
@import './blog/blog-details';



/**************************************
    Footer Styles
***************************************/
@import './template/banner';
@import './template/portfoliodetails';
@import './template/splash';



/**************************************
    Footer Styles
***************************************/

@import './footer/footer';
@import './footer/copyright';
@import './footer/darkmode';

body.light .header-transparent .mainmenu-nav .mainmenu > li > a:hover {
    color: #269AFD;
}
body.light .header-transparent .mainmenu-nav .mainmenu > li > a:hover::after {
    width: 100%;
    left: 0;
    right: auto;
}
.header-default .header-right
{
    justify-content: left !important;
}
.header-default .header-right .header-btn
{
    margin: 0 0 0 auto;
}
.section-title .subtitle {
    display: none;
}
body.light .header-transparent .mainmenu-nav .mainmenu > li > a
{
    font-size: 20px;
    padding: 0px 30px;
}
body.light .header-transparent .mainmenu-nav .mainmenu > li > a:after {
    position: absolute;
    content: "";
    left: auto;
    bottom: 0;
    background: currentColor;
    width: 0;
    height: 2px;
    transition: 0.3s;
    right: 0;
}
.mainmenu-nav .mainmenu > li > a {
    cursor: pointer;
}
// custom scss

.pricing-subtitle {
    font-weight: bold;
    color: var(--color-dark);
}
.rn-pricing.style-4.active {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.opacity_05 {
    opacity: 0.5;
}

.pricing-body {
    text-align: unset !important;
    display: flex;
    justify-content: center;
}

.rn-address {
    margin-top: 0 !important;
}

.grayScaleEffect {
    cursor: pointer;
    
    img {
        max-width: 70%;
        filter: grayscale(1);

        &:hover {
            filter: grayscale(0);
            transition: filter 0.7s;
        }
    }
}

.centerFlex {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    h1 {
        font-size: 3em;
    }
    p {
        font-size: 1.2em;
    }

    img {
        max-width: 600px;
        // max-height: 400px;
    }
}

.noPadding {
    padding: 0 !important;
}


.custom-about-page {
    p {
        font-size: 20px;
        font-weight: 400;
    }
}

.about-us-line-parallax {
    height: 250px;
    width: 100%;
    background-image: url('./../images/about-us-parallax.jpg');
    /* Set a specific height */
    min-height: 500px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.padding-top-none {
    padding-top: 0 !important;
}