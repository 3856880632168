
.rn-pricing {
    // margin-top: 40px;
    .pricing-table-inner {
        padding: 40px;
        position: relative;
        z-index: 2;
        border: 2px solid var(--color-border);
        transition: all 0.6s cubic-bezier(0.33, 0.84, 0.31, 0.98);
        transform-style: preserve-3d;
        &::before {
            z-index: -1;
            display: inline-block;
            content: '';
            transition: all 0.6s cubic-bezier(0.33, 0.84, 0.31, 0.98);
            opacity: 0;
            position: absolute;
            top: -2px;
            right: -2px;
            bottom: -2px;
            left: -2px;
            background-color: var(--color-lessdark);
        }
        .pricing-header {
            margin-bottom: 10px;
            padding-bottom: 20px;
            text-align: center;
            border-bottom: 2px solid var(--color-border);
            .title {
                margin-bottom: 20px;
            }
            .price-wrapper {
                display: flex;
                justify-content: center;
            }
            .pricing {
                span {
                    display: block;
                    &.currency {
                        font-size: 28px;
                        position: relative;
                        top: -3px;
                        left: -2px;
                        opacity: 0.3;
                    }
                    &.price {
                        font-size: 77px;
                        color: var(--color-white);
                        line-height: 1;
                        font-weight: 700;
                        margin-bottom: 10px;
                        @media #{$sm-layout} {
                            font-size: 54px;
                        }
                    }
                    &.subtitle {
                        font-size: 14px;
                        color: var(--color-body);
                    }
                }
            }
        }

        .pricing-body {
            text-align: center;
            min-height: 410px;
            .list-style--1 {
                list-style: none;
                padding: 0;
                margin: 0;
            }
        }

        .pricing-footer {
            text-align: center;
        }
    }


    &.style-2 {
        .pricing-table-inner {
            background-color: var(--color-blackest);
            border-color: transparent !important;
        }
    }

    &.style-4 {
        margin-left: -1px;
        margin-right: -1px;
        @media #{$md-layout} {
            margin-left: 15px;
            margin-right: 15px;
        }
        @media #{$sm-layout} {
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    &.active {
        .pricing-table-inner {
            // border-color: transparent !important;

            &::before {
                // transform: scaleY(1.12);
                // opacity: 1;
                @media #{$md-layout} {
                    transform: scaleY(1);
                }
                @media #{$sm-layout} {
                    transform: scaleY(1);
                }
            }

            .pricing-header {
                border-bottom: 2px solid var(--color-border);
                .title {
                    color: var(--color-white);
                }
                .pricing {
                    span {
                        display: block;
                        &.currency {
                            color: var(--color-white);
                            opacity: 1;
                        }

                        &.price {
                            color: var(--color-white);
                        }
                        &.subtitle {
                            color: var(--color-white);
                        }
                    }
                }
            }

            .pricing-body {
                ul {
                    &.list-style--1 {
                        li {
                            color: var(--color-white);
                            svg {
                                color: var(--color-white);
                            }
                        }
                    }
                }
            }

            .pricing-footer {
                text-align: center;
            }
        }
    }

    &.style-5 {
        .pricing-table-inner {
            background-color: var(--color-blackest);
            border-color: transparent;
            padding: 0;
            padding-bottom: 40px;
            border: 0 none;

            &::before {
                display: none;
            }

            .pricing-header {
                margin-bottom: 30px;
                padding-bottom: 0;
                border-bottom: 0 none !important;

                .title {
                    padding: 30px 40px;
                    margin-bottom: 0;
                    font-size: 19px;
                    font-weight: 500;
                    letter-spacing: 0.5px;
                }

                .price-wrapper {
                    margin-bottom: 5px;
                }

                .pricing {
                    span {
                        &.currency {
                            font-size: 40px;
                            position: relative;
                            top: 0px;
                            left: 0;
                            opacity: 1;
                            color: var(--color-white);
                            font-weight: 700;
                            line-height: 7px;
                            display: flex;
                            align-items: center;
                        }
                        &.price {
                            font-size: 50px;
                            color: var(--color-white);
                            line-height: 1;
                            font-weight: 700;
                            margin-bottom: 0;
                        }
                        &.subtitle {
                            color: var(--color-white);
                        }
                    }
                }
            }
            .pricing {
                background-color: var(--color-lessdark);
                padding: 30px;
            }
        }
    }
}
